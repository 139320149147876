#reporting {
	height: 100%;
	
	.reports-button {
		min-width: 36px;
		border-radius: 4px;
	}
	
	#report-selector {
		
		.report-list {
			padding: 16px 0;
			
			.report-list-item {
				min-width: 210px;
				width: 210px;
				padding: 24px 0;
				margin: 16px;
				border-radius: 2px;
				background: rgba(0, 0, 0, 0.12);
				cursor: pointer;
				
				&:hover {
					box-shadow: $whiteframe-shadow-4dp;
				}
				
				.report-name {
					padding-top: 16px;
					font-weight: 500;
				}
				
				//md-icon { color: rgba(255,255,255,0.7); }
				
				&.add-new-report {
					border-color: rgba(0, 0, 0, 0.3);
					background: rgba(0, 0, 0, 0.2);
				}
			}
		}
	}
	
	#empty-report {
		.layout-list {
			padding: 16px 0;
			
			.layout-list-item {
				padding: 4px;
				margin: 2px;
				border-radius: 2px;
				background: rgba(0, 0, 0, 0.08);
				cursor: pointer;
				
				&:hover {
					box-shadow: $whiteframe-shadow-4dp;
				}
				
				&.selected-report, &.selected-template {
					background: rgba(0, 0, 0, 0.2);
				}
				
				.page-layout-icon { opacity: 0.3; }
				
				//md-icon { color: rgba(0,0,0,0.4); }
			}
		}
	}
	
	
    #report {
        //width: 750px;
        transform: scale(1);
        transform-origin: top center;
		
		.page-cnt {
			position: relative;
			margin-bottom: 16px;
			
			
			&.a3 {
				width:  29.7cm;
				height: 42.0cm;
			}
			&.a4 {
				width:  21.0cm;
				height: 29.7cm;
				
			}
			&.a5 {
				width:  14.8cm;
				height: 21.0cm;
			}
			
			&.active-page {
				-webkit-box-shadow: 0px 1px 5px 3px rgba(17,130,196,0.5);
					-moz-box-shadow: 0px 1px 5px 3px rgba(17,130,196,0.5);
						box-shadow: 0px 1px 5px 3px rgba(17,130,196,0.5);
			}
			
			.page {
				position: absolute;
				
				&.normal-margins {
					top:    1.0cm;
					left:   1.25cm;
					bottom: 1.0cm;
					right:  1.25cm;
				}
				&.narrow-margins {
					top:    0.5cm;
					left:   0.5cm;
					bottom: 0.5cm;
					right:  0.5cm;
				}
				&.moderate-margins {
					top:    1.0cm;
					left:   0.75cm;
					bottom: 1.0cm;
					right:  0.75cm;
				}
				&.wide-margins {
					top:    1.0cm;
					left:   2cm;
					bottom: 1.0cm;
					right:  2cm;
				}
				
				
				
				
				
				
				
				
				
				/*
				// THEMES
				&.red {
					.header {
						border-bottom: 1px solid rgb(244,67,54);
					}
					.footer {
						border-top:1px solid rgb(244,67,54);
					}
					h1.theme, h2.theme {
						color:rgb(244,67,54);
					}
					div.theme.heavy {
						background:rgb(244,67,54);
						border-radius:6px;
						padding:10px;
						color:white;
					}
					div.theme.dark {
						background:rgb(97,97,97);
						border-radius:6px;
						padding:10px;
						color:white;
					}
					
					#numericCard {
						//border:2px solid rgb(244,67,54) !important;
						//background:rgba(244,67,54,0.2) !important;
					}
					
				}
				&.orange {
					.header {
						border-bottom:1px solid rgb(255,152,0);
						//background: url("../assets/logos/SmartEverything_small.png");
						//background-repeat: no-repeat;
						//background-position: right center;
					}
					.footer {
						border-top:1px solid rgb(255,152,0);
					}
					h1.theme, h2.theme {
						color:rgb(255,152,0);
					}
					div.theme.heavy {
						background:rgb(255,152,0);
						border-radius:6px;
						padding:10px;
						color:white;
					}
					div.theme.dark {
						background:rgb(97,97,97);
						border-radius:6px;
						padding:10px;
						color:white;
					}
				}
				&.purple {
					.header {
						border-bottom:1px solid rgb(156,39,176);
					}
					.footer {
						border-top:1px solid rgb(156,39,176);
					}
					h1.theme, h2.theme {
						color:rgb(156,39,176);
					}
					div.theme.heavy {
						background:rgb(156,39,176);
						border-radius:6px;
						padding:10px;
						color:white;
					}
					div.theme.dark {
						background:rgb(97,97,97);
						border-radius:6px;
						padding:10px;
						color:white;
					}
				}
				&.indigo {
					.header {
						border-bottom:1px solid rgb(63,81,181);
					}
					.footer {
						border-top:1px solid rgb(63,81,181);
					}
					h1.theme, h2.theme {
						color:rgb(63,81,181);
					}
					div.theme.heavy {
						background:rgb(63,81,181);
						border-radius:6px;
						padding:10px;
						color:white;
					}
					div.theme.dark {
						background:rgb(97,97,97);
						border-radius:6px;
						padding:10px;
						color:white;
					}
				}
				&.teal {
					.header {
						border-bottom:1px solid rgb(0,150,136);
					}
					.footer {
						border-top:1px solid rgb(0,150,136);
					}
					h1.theme, h2.theme {
						color:rgb(0,150,136);
					}
					div.theme.heavy {
						background:rgb(0,150,136);
						border-radius:6px;
						padding:10px;
						color:white;
					}
					div.theme.dark {
						background:rgb(97,97,97);
						border-radius:6px;
						padding:10px;
						color:white;
					}
				}
				&.blue-grey {
					.header {
						border-bottom:1px solid rgb(96,125,139);
					}
					.footer {
						border-top:1px solid rgb(96,125,139);
					}
					h1.theme, h2.theme {
						color:rgb(96,125,139);
					}
					div.theme.heavy {
						background:rgb(96,125,139);
						border-radius:6px;
						padding:10px;
						color:white;
					}
					div.theme.dark {
						background:rgb(97,97,97);
						border-radius:6px;
						padding:10px;
						color:white;
					}
				}
				*/
				
				
				
				
				.header {
					position:absolute;
					top:0;
					left:0;
					right:0;
					height:1.5cm;
					line-height:1.5cm;
					overflow: hidden;
					
					div p {
						margin:0;
					}
				}
				
				.footer {
					position:absolute;
					bottom:0;
					left:0;
					right:0;
					height:1cm;
					line-height:1cm;
					overflow: hidden;
					
					div p {
						margin:0;
					}
				}
				
				.content {
					position:absolute;
					top:1.5cm;
					bottom:1cm;
					left:0;
					right:0;
					
					&.grid {
						background-color: transparent;
						background-image: linear-gradient(0deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent);
						background-size: 1cm 1cm;
					}
					
					.blocks {
						overflow: hidden;
						position: absolute;
						background: #fafafa;
						//border-width:1px;
						//border-style:dotted;
						//border-color:#ccc;
						border:1px dotted #ccc;
						
						&.active-block {
							background: #fafafa;
							border:2px dotted rgba(17,130,196,0.5);
							//opacity:0.8;
						}
						
						.block-actions {
							position: absolute;
							top: 0;
							right: 0;
						}
						
						.block {
							padding:0 6px;
							
							h3.widget-title {
								margin: 0;
								font-weight: 600;
								//color: coral;
							}
							
							&.mce-content-body {
								h1, h2, h3, h4, h5 {
									margin-top:0;
								}
							}
							
							#numericCard {
								position: relative;
								top: 0;left: 0;
								-webkit-transform: translate(0, 0);
								transform: translate(0, 0);
								background:#ededed;
								//border:1px solid #616161;
								border-radius:6px;
								margin-top:0;
								padding:10px;
								
							}
							
						}
						
					}
					
				}
				
			}
		}
    }
	
	
	.vAccordion--default {
		
		v-accordion {
			margin-top:10px;
			padding-left:10px;
			
			v-pane-header {
				color: #999;
				border: none;
				margin-bottom: 0;
			
				&::after,
				&::before {
					width: 8px;
					height: 2px;
					right: 8px;
					background-color: #ccc;
				}
			}
			
		}
		
		v-pane-header {
			color: #999;
			border: none;
			margin-bottom: 0;
		
			&::after,
			&::before {
				width: 16px;
				height: 2px;
				right: 8px;
				background-color: #999;
			}
		}
		v-pane-content {
			> div {
				padding-bottom: 0;
			}
			
			#layout-selector {
				
				.layout-list {
					padding: 16px 0;
					
					.layout-list-item {
						padding: 4px;
						margin: 2px;
						border-radius: 2px;
						background: rgba(0, 0, 0, 0.08);
						cursor: pointer;
						
						&:hover {
							box-shadow: $whiteframe-shadow-4dp;
						}
						
						&.selected-report, &.selected-template, &.selected-theme {
							background: rgba(0, 0, 0, 0.2);
						}
						
						.page-layout-icon {
							width:22px;
							height:22px;
							margin:2px;
							opacity: 0.3;
						}
						
						//md-icon { color: rgba(0,0,0,0.4); }
					}
				}
			}
			
		}
	}
	
}

.mce-caret {
	min-width: 0 !important;
	min-height: 0 !important;
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
	
    #reports {
		
	}
	
}