#predictionPlot{
    .measureTitle{
        font-style: bold;
        font-size: 20px;
    }
     .measureText{
        font-size: 16px;
    }
    .measureUnit{
         font-style: italic;
    }
}
