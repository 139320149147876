// #gaugeCard {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     font-size: 35px;
//     margin-top: 25px; 
// }


#gauge-container {
    height: calc(100% - 45px);
    width: 100%;
}