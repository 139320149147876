.compare-filter-dialog {
    .schedulePicker {
        color: #999;
        font-size: 10px;
        margin: 0 auto;
        border-collapse: collapse;
        
        th, td { padding: 2px; }
        .th-day { text-align: right; padding: 2px 5px }
        .th-time { text-align: center; }
        th, td { padding: 2px; min-width: 20px; min-height: 20px;}
        td {
            border:1px solid rgba(255,255,255,0.3);
            background-color: rgba(0,0,0,0.1);
        }
        .highlighted {
            background-color: #129c05;
        }
        .semi-highlighted {
            background-color: #7cc67a;
        }
    }
}