/*-----------------------------------------------------------------------*/
/* Contains custom PRAGMA elements, components and general styles
/*-----------------------------------------------------------------------*/

/*-----------------------------*/
/* General [ ??????????? ]
/*-----------------------------*/

.hotTableHeder{
	margin-top: 0px; 
	-webkit-transform:rotate(-45deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	font-size: 0.8em; 
	text-align: center;
}

md-progress-circular{
	position: fixed;
	top:50%;
	left:50%;
	z-index:10;
}


.list-unstyled {
  padding-left: 0;
  list-style: none;
}


/*
.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
	display: table;
	content: " ";
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after {
	clear: both;
}
*/



/*-----------------------------*/
/* Alerts [ ??????????? ]
/*-----------------------------*/

.alert-success {
	color: #ffffff;
	background-color: rgba(38, 185, 154, 0.88);
	border-color: rgba(38, 185, 154, 0.88);
}

.alert-info {
	color: #E9EDEF;
	background-color: rgba(52, 73, 94, 0.88);
	border-color: rgba(52, 73, 94, 0.88);
}

.alert-warning {
	color: #E9EDEF;
	background-color: rgba(243, 156, 18, 0.88);
	border-color: rgba(243, 156, 18, 0.88);
}

.alert-danger,
.alert-error {
	color: #E9EDEF;
	background-color: rgba(231, 76, 60, 0.88);
	border-color: rgba(231, 76, 60, 0.88);
}


/*-----------------------------*/
/* Icon Sizes [ ??????????? ]
/*-----------------------------*/

md-icon {
	@include generate-icon-sizes();
}

/*-----------------------------*/
/* Notifications [ ??????????? ]
/*-----------------------------*/


.ui-notification {
    padding: 10px;
    width: 480px;
}


.notificationHeader {
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}



.custom-notifications {
	position: fixed;
	margin: 15px;
	right: 0;
	float: right;
	width: 400px;
	z-index: 4000;
	bottom: 0;
}

ul.notifications {
	float: right;
	display: block;
	margin-bottom: 7px;
	padding: 0;
	width: 100%;
}

.notifications li {
	float: right;
	margin: 3px;
	width: 36px;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3); }

.notifications li:last-child {
  margin-left: 0;
}
.notifications a {
  display: block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 9px 8px;
}

.tabbed_notifications .text {
  padding: 5px 15px;
  height: 140px;
  border-radius: 7px;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
}

.tabbed_notifications div p {
  display: inline-block;
}

.tabbed_notifications h2 {
  font-weight: bold;
  text-transform: uppercase;
  width: 80%;
  float: left;
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.tabbed_notifications .close {
  padding: 5px;
  color: #E9EDEF;
  float: right;
  opacity: 1;
}

.angular-notifications-container {
    font-size: 10px;
    line-height: 15px;
    
    .angular-notifications-icon {
        top: -3px;
        right: -8px;
        min-width: 20px;
        height: 20px;
    }
    
    md-icon {
        //font-size: 18px !important;
        //padding-top: 2px;
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
	
    .angular-notifications-container {
        font-size: 14px;
        line-height: 20px;
        
        .angular-notifications-icon {
            top: -6px;
            right: -12px;
            min-width: 25px;
            height: 25px;
        }
        md-icon {
            //padding-top: 5px;
            font-size: 24px !important;
        }
    }
}


/*-----------------------------*/
/* Portfolios [ ??????????? ]
/*-----------------------------*/


#portfolio {

    // Single scroll
    &.single-scroll {

        .portfolio-list-pane {
            overflow: hidden;
        }
    }

    .portfolio-list-pane {

        .portfolio-items {

            &.sortable {

                .portfolio-item {
                    padding: 16px 16px 16px 48px;

                    .handle {
                        display: block;
                    }
                }
            }
        }

        .portfolio-items {
            overflow-x: hidden;

        }
    }
}

.portfolio-item {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    text-transform: none;

    &.portfolio-item-placeholder {
        background: rgba(0, 0, 0, 0.12);
        * {
            opacity: 0;
        }
    }

    .tags {
        font-size: 12px;

        .tag {
            border-radius: 2px;
            margin: 8px 4px 0 0;
            padding: 3px 8px;
            background-color: rgba(0, 0, 0, 0.08);

            .tag-color {
                width: 8px;
                height: 8px;
                margin-right: 8px;
                border-radius: 50%;
            }
        }
    }

    &.completed {
        background: #EEEEEE;

        .title,
        .notes {
            color: rgba(0, 0, 0, 0.54);
            text-decoration: line-through;
        }
    }

    &.selected {
        background: #FFF8E1;
    }

    .info {
        margin: 0 16px 0 8px;

        .title {
            font-size: 15px;
            font-weight: 500;
        }

        .notes {
            margin-top: 4px;
        }
    }

    .buttons {

        .is-starred {
            margin: 0 0 0 16px;
        }

        .is-important {
            margin: 0;
        }
    }
}

.portfolio-item-ghost {
    opacity: 1 !important;
    background: #FFFFFF;
    box-shadow: $whiteframe-shadow-14dp;
    border: none;

    .md-ripple-container {
        display: none;
    }
}



/*-----------------------------*/
/* Group Buttons [ NEEDS REVISION ]
/*-----------------------------*/

.md-button {
	
	&.groupX {
		/*background-color: #f3f3f3 !important;
		background-image: linear-gradient(center top, #fefefe, #f3f3f3) !important;
			background-image: -o-linear-gradient(center top, #fefefe, #f3f3f3) !important;
			background-image: -webkit-linear-gradient(center top, #fefefe, #f3f3f3) !important;
			background-image: -moz-linear-gradient(center top, #fefefe, #f3f3f3) !important;
            */
		//border: 1px solid #ccc;
		color: #ccc !important;
		cursor: pointer;
		display: block;
		float: left;
		font-size: 11px;
        line-height: 11px;
		min-width: 18px;
		padding: 8px 7px;
		text-align: center;
		text-transform: none;
		
		&.active {
			/*background-color: #dfdfdf !important;
              background-image: linear-gradient(center top, #f0f0f0, #dfdfdf) !important;
				background-image: -o-linear-gradient(center top, #f0f0f0, #dfdfdf) !important;
				background-image: -webkit-linear-gradient(center top, #f0f0f0, #dfdfdf) !important;
				background-image: -moz-linear-gradient(center top, #f0f0f0, #dfdfdf) !important;
            */
			border-color: #999;
			box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3) inset !important;
			color: #fff !important;
		}
		
		&.left {
			border-right: none;
			border-radius: 3px 0 0 3px;
		}
		
		&.middle {
			border-radius: 3px;
			border-left: none;
			border-right: none;
		}
		
		&.right {
			border-left: none;
			border-radius: 0 3px 3px 0;
		}
		
	}
	
	&.download-button {
		padding: 0;
		margin-left: 20px !important;
		width: 32px; height: 32px;
		min-height: 32px;
	}
}



.zoom-ctrl {
    
	/* border:1px solid red; */
	width: 248px; height: 69px;
    position: absolute;
    top: 0; right: -24px;
    .zoom-box {
        padding: 0 24px;
        cursor: move; cursor: grab; cursor: -webkit-grab; cursor:-moz-grab;
        width: 100%; height: 69px;
        z-index: 999;
        
        > div {
            pointer-events: none;
            height: 69px;
            background-color: rgba(0,0,10,0.2);
            mix-blend-mode: multiply;
            background-blend-mode: multiply;
        }
    }
    .zoom-box:active {
        cursor: grabbing; cursor: -webkit-grabbing; cursor:-moz-grabbing;
    }
    .zoom-left, .zoom-right {
        cursor: ew-resize;
        position: absolute;
        top: 0;
        width: 48px; height: 69px;
        background: url("../assets/images/dragIcon.png") 0 12px no-repeat;
        z-index: 1000;
    }
    .zoom-right {
        right: 0;
    }
}







/*-----------------------------*/
/* Monitored points
/*-----------------------------*/

.search-wrapper {
	margin-bottom: 10px;
	border-bottom: 1px solid #ccc;
	
	.search {
		height: 36px;
		line-height: 36px;
		padding: 8px;
		background: #FFFFFF;
		font-size: 13px !important;
		
		.icon {
			margin: 0;
			color: rgba(0, 0, 0, 0.54);
		}
		
		input {
			padding-left: 12px;
			height: 36px;
			color: rgba(0, 0, 0, 0.54);
		}
	}
}

.tree {
	width: 100%;
	overflow: hidden;
	
	.jstree-proton, 
	.jstree-wholerow-clicked {
		//background: rgba(158,158,158,0.2);
		background: transparent;
	}
	
	.jstree-proton, 
	.jstree-wholerow-hovered {
		//background: rgba(158,158,158,0.1);
		background: transparent;
	}
	
	//.jstree-proton, 
	//.jstree-clicked {
	//	color: rgba(158,158,158,0.9);
	//}
	
	//.jstree-proton, 
	//.jstree-hovered {
	//	color: red;
	//}

	
	
}



/*-----------------------------*/
/* Date Range Block
/*-----------------------------*/

/* Mine */
.daterange-wrapper {
	position: relative;
	min-width: 256px;
	//background: #f3f3f3;
	//box-shadow: $whiteframe-shadow-1dp;
	//box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	
	border: 1px solid #ccc;
	//border-top: 1px solid #ccc;
	//border-bottom: 1px solid #ccc;
	
	
	.daterange {
		height: 36px;
		line-height: 36px;
		padding: 0;
		//background: #f3f3f3;
		font-size: 16px;
		font-weight: 500;
		
		
		md-icon {
			//border-right:1px solid #ccc;
			width: 36px;
			height: 36px;
			min-width: 36px;
			min-height: 36px;
			line-height: 36px;
			text-align:center;
			//margin: 0;
			//color: rgb(33,33,33);
		}
		
		input {
			//background: #f3f3f3;
			box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.26);
			padding-left: 12px;
			height: 36px;
			color: rgb(33,33,33);
            border-radius: 3px;
			//cursor:pointer;
		}
	}
}

/*
.daterange-wrapper {
	position: relative;
	min-width: 256px;
	background: #f3f3f3;
	//box-shadow: $whiteframe-shadow-1dp;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	
	
	.daterange {
		height: 34px;
		line-height: 34px;
		padding: 5px;
		background: #f3f3f3;
		font-size: 16px;
		font-weight: 500;min-width: 256px;
		
		.icon {
			margin: 0;
			color: rgb(33,33,33);
		}
		
		input {
			background: #f3f3f3;
			padding-left: 12px;
			height: 34px;
			color: rgb(33,33,33);
		}
	}
}
*/

.daterangepicker {
	display: none;
	box-shadow: $whiteframe-shadow-1dp;
	padding: 8px;
	
	.applyBtn {
		font-size: 11px;
		min-width: 70px;
		min-height: 30px;
		line-height: 30px;
	}
	
	td.in-range {
		background-color: #f5f5f5;
	}
	
	.fa {
		font-style: normal;
		font-family: icomoon;
	}
	
	.fa-chevron-left:before {
		content: "\e712";
	}
	
	.fa-chevron-right:before {
		content: "\e713";
	}

    .calendar-time {
        text-align: right;
        padding: 0;
        margin-bottom: 0;

        &:before {
            display: inline-block;
            content: 'Time:';
            position: absolute;
            right: 120px;
        }
    }

    select.hourselect, select.minuteselect {
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #555;
        height: 30px;
        line-height: 30px;
        // display: block;
        // vertical-align: middle;
        // margin: 0 0 5px 0;
        // padding: 0 6px 0 28px;
        // width: 100%;
        option {
            color: #555;
        }
    }

}



/*-----------------------------*/
/* Grid Stack
/*-----------------------------*/

.grid-stack-item-content {
	box-shadow: $whiteframe-shadow-1dp;
	
	.md-button {
		&.edit-button,
		&.delete-button {
			margin:0;
			padding:0;
		}
	}
	
}



/*-----------------------------*/
/* Dialogues
/*-----------------------------*/

md-dialog {
	min-width: 400px;
	// min-height: 400px;
	
	.unit { font-size: 60%; }
	
	&.ha-info {
		width: 600px;
		max-width: 600px;
		overflow: hidden;
	}
	
	&.ha-options {
		width: 600px;
		max-width: 600px;
		overflow: hidden;
		
		.checked {
			color: #259de1;
		}
		.open {
			color: #f41948;
		}
		.temperature {
			white-space: nowrap;
			color: #f41948;
		}
		.first_letter:first-letter {
			text-transform: uppercase;
		}
	}
	
	&.new-event-dialog {
		width: 600px;
		max-width: 600px;
		overflow: hidden;
	}
	
    &.new-user-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    &.edit-user-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    &.new-client-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    &.edit-client-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    &.new-widget-dialog {
        width: 50%;
        max-width: 50%;
        overflow: hidden;
    }
    
    &.new-portfolio-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.edit-portfolio-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.manage-portfolio-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.new-site-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
		
		md-dialog-content {
			overflow: hidden;
		}
		
		md-tabs {
			height: 450px;
			max-height: 800px;
		}
		md-tabs-wrapper {
			position : fixed;
			width: 100%;
			z-index: 1;
		}
		md-tabs-content-wrapper {
			//margin-top: 62px;
		}
    }
    &.edit-site-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
		
		md-dialog-content {
			overflow: hidden;
		}
		
		md-tabs {
			height: 450px;
			max-height: 800px;
		}
		md-tabs-wrapper {
			position : fixed;
			width: 100%;
			z-index: 1;
		}
		md-tabs-content-wrapper {
			//margin-top: 62px;
		}
    }
	
    &.new-cost-profile-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.edit-cost-profile-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.new-assetcat-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.edit-assetcat-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.new-asset-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.edit-asset-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.new-spec-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
		
		md-dialog-content {
			overflow: hidden;
		}
		
		md-tabs {
			height: 450px;
			max-height: 800px;
		}
		md-tabs-wrapper {
			position : fixed;
			width: 100%;
			z-index: 1;
		}
		md-tabs-content-wrapper {
			//margin-top: 62px;
		}
    }
    
    &.edit-spec-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
		
		md-dialog-content {
			overflow: hidden;
		}
		
		md-tabs {
			height: 450px;
			max-height: 800px;
		}
		md-tabs-wrapper {
			position : fixed;
			width: 100%;
			z-index: 1;
		}
		md-tabs-content-wrapper {
			//margin-top: 62px;
		}
    }
	
    &.new-device-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.edit-device-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.new-deviceGroup-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.edit-deviceGroup-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.new-dvc-grp-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.new-notification-dialog {
        width: 800px;
        max-width: 800px;
        overflow: hidden;
		
		md-dialog-content {
			//overflow: hidden;
		}
		
		md-tabs {
			height: 450px;
			max-height: 800px;
		}
		md-tabs-wrapper {
			position : fixed;
			width: 100%;
			z-index: 1;
		}
		md-tabs-content-wrapper {
			//margin-top: 62px;
		}
    }
    
    &.edit-notification-dialog {
        width: 800px;
        max-width: 800px;
        overflow: hidden;
		
		md-dialog-content {
			//overflow: hidden;
		}
		
		md-tabs {
			height: 450px;
			max-height: 800px;
		}
		md-tabs-wrapper {
			position : fixed;
			width: 100%;
			z-index: 1;
		}
		md-tabs-content-wrapper {
			//margin-top: 62px;
		}
    }
	
    &.new-vertical-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.edit-vertical-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
	
    &.new-cost-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
        
        simple-handsontable {
            font-size: 0.8em;
            .colHeader {
                font-size: 0.8em;
            }
            th, td {
                text-align: center;
            }
        }
        
    }
	
    &.edit-cost-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
        
        simple-handsontable {
            font-size: 0.8em;
            .colHeader {
                font-size: 0.8em;
            }
            th, td {
                text-align: center;
            }
        }
        
    }
	
    &.portfolio-dialog {
        max-width: 96%;
        width: 96%;
        max-height: 96%;
        height: 96%;
        margin: 2%;

        md-dialog-content {
            display: block;
            position: relative;
            padding: 0;
        }

        md-toolbar {
            .title {
                font-size: 17px;
            }
        }
        
        md-input-container {
            textarea {
                min-height: 150px;
            }
        }

        ui-gmap-google-map {
            width: 100%;
        }
        
        .angular-google-map-container {
            width: 100%;
            height: 200px;
        }

        .date-container {
            padding: 16px 0 24px 0;
        }

        .notes {

            > label {
                color: rgba(0, 0, 0, 0.54);
            }

            .mce-tinymce {
                margin-top: 8px;
            }
        }

        .tags {
            margin: 8px 0;

            label {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.54);
            }

            .md-chips {

                .md-chip {

                    md-chip-template {

                        .color {
                            display: inline-block;
                            position: relative;
                            width: 8px;
                            height: 8px;
                            top: -1px;
                            border-radius: 50%;
                            margin: -2px 4px 0 0;
                        }
                    }
                }
            }
        }

        .attachment-list {
            font-size: 13px;
            padding-top: 16px;

            .attachment {
                background-color: rgba(0, 0, 0, 0.08);
                border: 1px solid rgba(0, 0, 0, 0.12);
                padding-left: 16px;
                margin-top: 8px;
                border-radius: 2px;

                .filename {
                    font-weight: 500;
                }

                .size {

                }

                .md-button {
                    margin: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            //background-color: rgba(0, 0, 0, 0.03);
            //border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
	
    &.location-edit-dialog {
        max-width: 720px;
        width: 720px;

        md-dialog-content {
            display: block;
			padding-top: 0;
            position: relative;
			
			.md-datepicker-input-container { width: 100% }
        }

        md-toolbar {

            .title {
                font-size: 17px;
            }
        }

        md-tabs {
			
			margin-left: -24px;
			margin-right: -24px;

			> md-tabs-wrapper {
				//background: #FFFFFF;
				box-shadow: $whiteframe-shadow-1dp;
				padding: 0 24px 12px;

				md-tabs-canvas {
					height: 56px;

					md-pagination-wrapper {
						height: 56px;
					}

					.md-tab {
						padding: 16px 24px;
						text-transform: none;
					}
				}
			}

			&:not(.md-dynamic-height) {

				md-tabs-content-wrapper {
					top: 56px;
				}
			}

			> md-tabs-content-wrapper {

				md-tab-content {
					padding: 24px 36px 0;
				}
			}
		}
		
        md-input-container {

            textarea {
                min-height: 150px;
            }
        }

        .date-container {
            padding: 16px 0 24px 0;
        }

        .notes {

            > label {
                color: rgba(0, 0, 0, 0.54);
            }

            .mce-tinymce {
                margin-top: 8px;
            }
        }

        .tags {
            margin: 8px 0;

            label {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.54);
            }

            .md-chips {

                .md-chip {

                    md-chip-template {

                        .color {
                            display: inline-block;
                            position: relative;
                            width: 8px;
                            height: 8px;
                            top: -1px;
                            border-radius: 50%;
                            margin: -2px 4px 0 0;
                        }
                    }
                }
            }
        }

        .attachment-list {
            font-size: 13px;
            padding-top: 16px;

            .attachment {
                background-color: rgba(0, 0, 0, 0.08);
                border: 1px solid rgba(0, 0, 0, 0.12);
                padding-left: 16px;
                margin-top: 8px;
                border-radius: 2px;

                .filename {
                    font-weight: 500;
                }

                .size {

                }

                .md-button {
                    margin: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            //background-color: rgba(0, 0, 0, 0.03);
            //border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
	
	md-dialog-content {
		position: relative;
		padding: 0;
		
		input, label {
			font-size: 16px;
		}
		
		.form-content {
			padding: 16px;
			font-size: 16px;
			
			.hasError {
				color: red;
			}
			
			p.message { margin: 20px; }
			
			.form-title {
				font-weight: bold;
				margin: 16px 0;
				padding: 0 8px;
				border-bottom: 1px solid #ccc;
				
				span {
					font-weight: bold;
				}
			}
			
			.input-block {
				padding: 12px;
				
				md-input-container, .md-datepicker-input-container {
					margin: 0;
					width: 100%;
				}
				
				md-switch {
					margin: 0;
				}
				
				.md-errors-spacer {
					height: 0;
					min-height: 0;
				}
				
				.ta-editor.ta-html, .ta-scroll-window.form-control, .ta-scroll-window > .ta-bind {
					min-height: 150px;
				}
			}
			
            .upload-button {
                margin: 0 0 0 6px;
                width: 40px;
                min-width: 40px;
                height: 40px;
                min-height: 40px;
            }
            
            .avatar {
                margin: 0 16px 0 8px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }
            
            .media {
                width: 150px;
                height: 40px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                margin: 0 16px 0 8px;
            }
			
		}
		
		md-dialog-actions {
			//border-top: 1px solid rgba(0, 0, 0, .12);
			//background-color: rgba(0, 0, 0, .03);
		}
		
        md-tabs {
            md-tabs-wrapper {
                box-shadow: $whiteframe-shadow-2dp;
            }
        }
		
	}
}


/*-----------------------------*/
/* Cards
/*-----------------------------*/

.ms-card {

    .event-card {

        .caption {
            text-align: center;
        }
        
        .chart {
            min-width: 360px;
        }
    }
}


/*-----------------------------*/
/* Contracts Box [ ????????? ]
/*-----------------------------*/

#contract {

    // Contract boxes
    .contract-box {
        margin: 8px 8px 8px 16px;

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 24px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .about-tab {
        max-width: 1200px;

        .about {
            padding: 8px;

            .general {

                .location {

                    md-icon {
                        line-height: 13px !important;
                    }
                }
            }

            .work {

                .job {

                    .company {
                        padding: 0 16px 0 0;
                        font-weight: 500;
                    }

                    .date {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }

        .about-sidebar {
            padding: 8px 8px 8px 32px;

            .friends {

                .content {

                    .friend {
                        padding: 4px;
                    }
                }
            }

            .groups {

                .content {

                    .group {
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .logo {
                            border: 1px solid rgba(0, 0, 0, 0.12);
                            margin-right: 16px;
                        }

                        .name {
                            font-weight: 500;
                            font-size: 15px;
                        }

                        .category,
                        .members {
                            color: rgba(0, 0, 0, 0.54);
                        }

                        .members {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
	
}



/*-----------------------------*/
/* BORDER DEV HELPERS
/*-----------------------------*/


.b-red {
    border: 1px solid red !important;
}

.b-green {
    border: 1px solid green !important;
}

.b-blue {
    border: 1px solid blue !important;
}

.b-orange {
    border: 1px solid orange !important;
}

.b-purple {
    border: 1px solid purple !important;
}


/*-----------------------------*/
/* RESPONSIVE
/*-----------------------------*/

@media screen and (max-width: $layout-breakpoint-md) {

    md-dialog {

        &.location-edit-dialog {
            width: 80%;
        }
    }
	
    .ms-card {
        
        .event-card {
        
            .chart {
                min-width: initial;
            }
            
        }
    }
	
}


@media screen and (max-width: $layout-breakpoint-sm) {
	
	md-dialog {
		width: 80%;
		
		md-dialog-content .form-content .input-block {
			width: 100%;
		}
	}
	
    #contract {

        .header {

            .contract-image {
                margin: 0 0 16px 0;
            }

            .name {
                margin-bottom: 32px;
            }
        }
    }
	
}


@media screen and (max-width: $layout-breakpoint-xs) {
	
	//.toolbar {margin-top: 10px;}
	
	.top-toolbar {
		.daterange-wrapper,
		.button-group {
			margin-top:8px;
		}
	}
	
	md-dialog {
		width: 90%;
		
        &.location-edit-dialog {
            width: 90%;
        }
		
	}
	
    #portfolio {

        .portfolio-list-pane {

            .sortable {

                .portfolio-items {

                    .portfolio-item {
                        padding-left: 32px;
                    }
                }
            }

            .portfolio-items {

                .portfolio-item {

                    .handle {
                        width: 32px;
                    }
                }
            }
        }
    }
	
}

.color-picker-actions {
    font-size: 11px;
    button {
        width: calc(50% - 6px) !important;
    }
}
