#admin {
	
	.custom-icon-button-wrapper {
		margin:6px;
		line-height: 56px;
		min-width: 0;
		width: 56px;
		height: 56px;
		vertical-align: middle;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
		border-radius: 50%;
		
		.md-button.md-icon-button {
			margin:6px 8px;
		}
		
		
	}
	
	.header {
		
		.tenant-selector {
			background: rgba(0, 0, 0, 0.32);
			height:44px;
			border-radius: 0;
			margin-right:1px;
		}
		
		.selected-tenant {
			background: rgba(0, 0, 0, 0.32);
			font-size:16px;
			height:44px;
			padding:0 8px;
			min-width:180px;
			font-weight:300;
		}
		
		
		md-input-container {
		
			.md-errors-spacer {
				display: none;
			}
			
			input {
				border-bottom: 1px solid rgba(255, 255, 255, 0.8);
				color: rgba(255, 255, 255, 0.8);
				width: 240px;
				
				&::placeholder {
					color: rgba(255, 255, 255, 0.8);
				}
				
				&:focus {
					border-bottom: 1px solid white;
					color: white;
				}
			}
		}
		
	}
	
	.page-content {
		
		.md-title {
			//min-height: 56px;
			
			/*span {
				position: absolute;
				margin-top: -10px;
				margin-left: 94px;
				display: block;
				font-size: 14px;
				font-weight: normal;
				max-width: 50%;
			}*/
		}
		
		.add-button {
			margin: 0;
		}
		
		.md-table-pagination {
			border-top: none;
		}
            
		table {
		
			thead tr.md-row {
				height: 64px;
				//background-color: #f5f5f5;
			}
			
			th {
				font-size: 14px;
			}
			
			th:first-child, td:first-child {
				padding: 0 8px;
				width: 96px;
				min-height: 70px;
			}
			
			th:last-child {
				text-align: center;
				width: 132px;
				white-space: nowrap;
			}
			
			td:last-child {
				width: 132px;
				white-space: nowrap;
			}
			
			&.clients th:last-child, &.clients td:last-child {
				width: 224px;
			}
			
			&.portfolios th:last-child, &.portfolios td:last-child {
				width: 187px;
			}
			
			&.manage-portfolio th:last-child, &.manage-portfolio td:last-child {
				width: 76px;
			}
			
			&.manage-portfolio td:first-child {
				padding-left: 32px;
			}
			
			.media-wide {
				cursor: pointer;
				margin: 14px !important;
				width: 150px;
				height: 40px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
			
			.media {
				cursor: pointer;
				margin: 6px 14px !important;
				width: 150px;
				height: 56px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
		}
		
		.manage {
			
			> .manage-top {
				//background: #FFFFFF;
				box-shadow: $whiteframe-shadow-1dp;
				height: 56px;
				padding: 16px 24px;
				
				.title {
					padding-left: 24px;
					font-size: 14px;
					font-weight: 500;
				}
				
				.close-page {
					//margin-right: 18px;
				}
			}
			
			> .manage-bottom {
				padding: 16px 32px;
			}
		}
	}
	
    .vertical-divider {
        margin: 12px 16px;
        display: inline-block;
        border-top-width: 0;
        border-right-width: 1px;
        border-right-color: rgba(0,0,0,0.12);
        border-right-style: solid;
        height: 32px;
    }
	
}









/* 
#admin {
    position: relative;

    > .center {
        
        > .header {
            height: auto;
            min-height: 88px;
            background-image: none;

                .selected-tenant {
                    display: inline-block;
                    background: rgba(0, 0, 0, 0.32);
                    color: #FFFFFF;
                    padding: 8px 16px;
                    height: 40px;
                    min-width: 180px;
                    line-height: 24px;
                    font-size: 16px;
                    margin: 0;
                }

                .tenant-selector {
                    margin-left: -1px;
                    margin-top: -3px;
                    border-radius: 0;
                    height: 40px;
                    font-size: 16px;
                    background: rgba(0, 0, 0, 0.32);
                    padding: 0;

                    md-icon {
                        color: #FFFFFF;
                    }
                }

            .logo {

                .logo-icon {
                    margin: 0 16px 0 0;
                }

                .logo-text {
                    font-size: 24px;
                }
            }

            md-icon {
                color: #FFFFFF;
            }
            
            md-input-container {

                .md-errors-spacer {
                    display: none;
                }

                input {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                    color: rgba(255, 255, 255, 0.8);
                    width: 240px;

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.8);
                    }

                    &:focus {
                        border-bottom: 1px solid white;
                        color: white;
                    }

                }
            }
        }
        
        > .content {
            background: none;
            padding: 0;

            > md-tabs {

                > md-tabs-wrapper {
                    //background: #FFFFFF;
                    box-shadow: $whiteframe-shadow-1dp;
                    padding: 0 24px;

                    md-tabs-canvas {
                        height: 56px;

                        md-pagination-wrapper {
                            height: 56px;
                        }

                        .md-tab {
                            padding: 16px 24px;
                            text-transform: none;
                        }
                    }
                }

                &:not(.md-dynamic-height) {

                    md-tabs-content-wrapper {
                        top: 56px;
                    }
                }

                > md-tabs-content-wrapper {

                    md-tab-content {
                        padding: 16px 32px;
                    }
                }
            }
            
            .md-title {
                
                min-height: 56px;
                
                span {
                    position: absolute;
                    margin-top: -10px;
                    margin-left: 94px;
                    display: block;
                    font-size: 14px;
                    font-weight: normal;
                    max-width: 50%;
                }
            }
            
            .add-button {
                margin: 0 16px;
            }
            
            .search-wrapper {
                
                margin-bottom: 10px;
                
                .search {
                    height: 36px;
                    line-height: 36px;
                    padding: 8px;
                    background: #FFFFFF;
                    font-size: 13px;

                    .icon {
                        margin: 0;
                        color: rgba(0, 0, 0, 0.54);
                    }

                    input {
                        padding-left: 12px;
                        height: 36px;
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
            
            .md-table-pagination {
                border-top: none;
            }
            
            table {
            
                thead tr.md-row {
                    height: 64px;
                    background-color: #f5f5f5;
                }
                
                th {
                    font-size: 14px;
                }
                
                th:first-child, td:first-child {
                    padding: 0 8px;
                    width: 96px;
                    min-height: 70px;
                }
                
                th:last-child {
                    text-align: center;
                    width: 132px;
                    white-space: nowrap;
                }
                
                td:last-child {
                    width: 132px;
                    white-space: nowrap;
                }
                
                &.clients th:last-child, &.clients td:last-child {
                    width: 224px;
                }
                
                &.portfolios th:last-child, &.portfolios td:last-child {
                    width: 187px;
                }
                
                &.manage-portfolio th:last-child, &.manage-portfolio td:last-child {
                    width: 76px;
                }
                &.manage-portfolio td:first-child {
                    padding-left: 32px;
                }
                
                .media-wide {
                    cursor: pointer;
                    margin: 14px !important;
                    width: 150px;
                    height: 40px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
                
                .media {
                    cursor: pointer;
                    margin: 6px 14px !important;
                    width: 150px;
                    height: 56px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }
            
            .manage {
                
                > .manage-top {
                    //background: #FFFFFF;
                    box-shadow: $whiteframe-shadow-1dp;
                    height: 56px;
                    padding: 16px 24px;
                    
                    .title {
                        padding-left: 24px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    
                    .close-page {
                        //margin-right: 18px;
                    }
                }
                
                > .manage-bottom {
                    padding: 16px 32px;
                }
            }
        }
    }
    
    .vertical-divider {
        margin: 12px 16px;
        display: inline-block;
        border-top-width: 0;
        border-right-width: 1px;
        border-right-color: rgba(0,0,0,0.12);
        border-right-style: solid;
        height: 32px;
    }
}
    
md-dialog {
    
    min-width: 400px;
    
    &.new-user-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    &.new-widget-dialog {
        width: 70%;
        max-width: 70%;
        overflow: hidden;
    }
    &.new-tenant-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.new-portfolio-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.manage-portfolio-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.new-site-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.new-cost-profile-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
    }
    
    &.new-cost-dialog {
        width: 600px;
        max-width: 600px;
        overflow: hidden;
        
        simple-handsontable {
            font-size: 0.8em;
            .colHeader {
                font-size: 0.8em;
            }
            th, td {
                text-align: center;
            }
        }
        
    }
    
    md-dialog-content {
        position: relative;
        padding: 0;

        input, label {
            font-size: 16px;
        }
        
        .form-content {
            padding: 16px;
            font-size: 16px;
            
            .hasError {
                color: red;
            }
            
            p.message { margin: 20px; }

            .angular-google-map-container {
                width: 100%;
                height: 200px;
            }
            
            .form-title {
                font-weight: bold;
                margin: 16px 0;
                padding: 0 8px;
                border-bottom: 1px solid #ccc;
                
                span {
                  font-weight: bold;
                }
            }
            
            .input-block {
                
                padding: 12px;
                
                md-input-container, .md-datepicker-input-container {
                    margin: 0;
                    width: 100%;
                }
                
                md-switch {
                    margin: 0;
                }
                
                .md-errors-spacer {
                    height: 0;
                    min-height: 0;
                }
            }
            
            .upload-button {
                margin: 0 0 0 6px;
                width: 40px;
                min-width: 40px;
                height: 40px;
                min-height: 40px;
            }
            
            .avatar {
                margin: 0 16px 0 8px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }
            
            .media {
                width: 150px;
                height: 40px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                margin: 0 16px 0 8px;
            }
        }

        md-dialog-actions {
            border-top: 1px solid rgba(0, 0, 0, .12);
            background-color: rgba(0, 0, 0, .03);
        }

        md-tabs {
            md-tabs-wrapper {
                box-shadow: $whiteframe-shadow-2dp;
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    md-dialog {
        width: 80%;
        md-dialog-content .form-content .input-block {
            width: 100%;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-xs) {

    md-dialog {
        width: 90%;
    }
}


md-dialog {

    &.portfolio-dialog {
        max-width: 96%;
        width: 96%;
        max-height: 96%;
        height: 96%;
        margin: 2%;

        md-dialog-content {
            display: block;
            position: relative;
            padding: 0;
        }

        md-toolbar {
            .title {
                font-size: 17px;
            }
        }
        
        md-input-container {
            textarea {
                min-height: 150px;
            }
        }

        ui-gmap-google-map {
            width: 100%;
        }
        
        .angular-google-map-container {
            width: 100%;
            height: 200px;
        }

        .date-container {
            padding: 16px 0 24px 0;
        }

        .notes {

            > label {
                color: rgba(0, 0, 0, 0.54);
            }

            .mce-tinymce {
                margin-top: 8px;
            }
        }

        .tags {
            margin: 8px 0;

            label {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.54);
            }

            .md-chips {

                .md-chip {

                    md-chip-template {

                        .color {
                            display: inline-block;
                            position: relative;
                            width: 8px;
                            height: 8px;
                            top: -1px;
                            border-radius: 50%;
                            margin: -2px 4px 0 0;
                        }
                    }
                }
            }
        }

        .attachment-list {
            font-size: 13px;
            padding-top: 16px;

            .attachment {
                background-color: rgba(0, 0, 0, 0.08);
                border: 1px solid rgba(0, 0, 0, 0.12);
                padding-left: 16px;
                margin-top: 8px;
                border-radius: 2px;

                .filename {
                    font-weight: 500;
                }

                .size {

                }

                .md-button {
                    margin: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
	
	.unit { font-size: 60%; }
}

#contract {

    // Contract boxes
    .contract-box {
        margin: 8px 8px 8px 16px;

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 24px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .about-tab {
        max-width: 1200px;

        .about {
            padding: 8px;

            .general {

                .location {

                    md-icon {
                        line-height: 13px !important;
                    }
                }
            }

            .work {

                .job {

                    .company {
                        padding: 0 16px 0 0;
                        font-weight: 500;
                    }

                    .date {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }

        .about-sidebar {
            padding: 8px 8px 8px 32px;

            .friends {

                .content {

                    .friend {
                        padding: 4px;
                    }
                }
            }

            .groups {

                .content {

                    .group {
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .logo {
                            border: 1px solid rgba(0, 0, 0, 0.12);
                            margin-right: 16px;
                        }

                        .name {
                            font-weight: 500;
                            font-size: 15px;
                        }

                        .category,
                        .members {
                            color: rgba(0, 0, 0, 0.54);
                        }

                        .members {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
	
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

    md-dialog {

        &.portfolio-dialog {
            width: 100%;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {

    md-dialog {

        &.portfolio-dialog {
            width: 100%;
        }
    }
	
    #contract {

        .header {

            .contract-image {
                margin: 0 0 16px 0;
            }

            .name {
                margin-bottom: 32px;
            }
        }
    }
}
*/