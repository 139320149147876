/*----------------------------------------------------------------*/
/*  PRAGMA Content Page Basic Elements
/*----------------------------------------------------------------*/

.page-content {
	
	.h1.page-content-header {
		font-weight: 300;
		padding: 16px 26px;
	}
	
	.content-top,
	.top-toolbar {
		background-color: material-color('white');
		box-shadow: $whiteframe-shadow-1dp;
		min-height: 64px;
		padding:0 16px;
		
		md-select {
			margin:0;
		}
		
		
	}
	
	.toolbar,
	.button-group {
		.md-button {
			&.groupX {
				padding: 6px 6px;
				margin: 0;
			}
		}
	}
    
	> md-tabs {
		> md-tabs-wrapper {
			/*background-color: material-color('white');*/
			box-shadow: $whiteframe-shadow-1dp;
			padding: 0 24px;
			
			md-tabs-canvas {
				height: 56px;
				
				md-pagination-wrapper {
					height: 56px;
				}
				
				.md-tab {
					padding: 16px 24px;
					text-transform: none;
				}
			}
		}
		
		&:not(.md-dynamic-height) {
			
			md-tabs-content-wrapper {
				top: 56px;
			}
		}
		
		> md-tabs-content-wrapper {
			
			md-tab-content {
				padding: 24px;
			}
		}
		
	}
	
}