/*----------------------------------------------------------------*/
/*  PRAGMA Variables
/*----------------------------------------------------------------*/

$pragma-header-height: 0;
$pragma-sidenavMobileWidth: 100%;

// Sidenav Widths
$sidenavWidths: (250, 300, 350, 400);

$iconSizes: (24, 32, 48, 56, 64, 72, 96);