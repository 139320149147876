$pragma-horizontal-navigation-height: 44px;

#layout-horizontal-navigation {

	#horizontal-navigation-wrapper {
		width:100%;
		
		#horizontal-navigation {
			
			.ms-navigation-horizontal {
				border-width: 1px;
				min-height: $pragma-horizontal-navigation-height;
				
				.horizontal {
					> li {
						> .ms-navigation-horizontal-item {
							height: $pragma-horizontal-navigation-height;
							
							&:hover {
								//background: #e1e1e1;
							}
							
							> .ms-navigation-horizontal-button {
								font-weight:500;
								border:none;
								
								&:hover {
									//background: #e0e0e0;
								}
								
								&.active {
									border:none;
								}
							}
							
						}
						
						ul {
							border:1px solid #bfbfbf;
							border-radius:4px;
							padding:6px 0;
							
							li {
								
								.ms-navigation-horizontal-item {
									
									.ms-navigation-horizontal-button {
										
										&.active {
											background-color:#f2f2f2 !important;
											color: rgba(0, 0, 0, 0.87) !important;
											
											.icon {
												color: rgba(0, 0, 0, 0.87) !important;
											}
											
										}
										
									}
								}
							}
						}
						
						
					}
					
					/*
					.has-children {
						ul {
							border:1px solid #000;
							box-shadow:none;
							border-radius:12px;
							
							li {
								> .ms-navigation-horizontal-item {
									
									> .ms-navigation-horizontal-button {
										
										&.active {
											background:#000 !important;
											border-radius:12px;
										}
									}
									
								}
								
							}
							
							
						}
					}
					*/
					
				}
			}
		}
		
	}

}




// RESPONSIVE
@media screen and (min-width: $layout-breakpoint-sm) {
	
	#layout-horizontal-navigation {

		#horizontal-navigation-wrapper {
			
			#horizontal-navigation {
				
				.ms-navigation-horizontal {
					background:none;
				}
				
			}
			
		}
		
	}
	
}

@media screen and (max-width: $layout-breakpoint-sm) {
	
	#horizontal-navigation {
		
		.ms-navigation-horizontal {
			border-width: 0;
			
			.horizontal {
				> li {
					
					ul {
						border:none;
						border-radius:0;
						padding:0;
					}
				}
			}
		}
	}
}
