#preferences {
	
	.form-title {
		border-bottom: 1px solid #ccc;
		margin-bottom: 5px;
	}
	
	
	.themes {
		md-radio-button {
			margin-bottom:16px;
		}
	}
	
	.theme-preview {
		
		> .header {
			> .toolbar {
				min-height:80px;
				
				.preferences-button {
					position:relative;
					left:10px;
					top:20px;
				}
			}
		}
		
		> .body {
			
			
			> .side {
				min-width:30%;
			}
			
			> .content {
				min-height:100px;
			}
			
		}

	}
	
}