/*----------------------------------------------------------------*/
/*  PRAGMA Page Layouts
/*----------------------------------------------------------------*/


[md-theme="Dark-Purple"] .template-layout {
	#header-container {
		&.grocery-hack {
			background: #4d4d5b;
		}

	}
}

.template-layout {
	#header-container {
		z-index: 54;

		.md-tab {
			font-size: 18px;
			font-weight: 600;
			text-transform: lowercase !important;

			&.md-disabled {
				opacity: 0.3;
				font-weight: 500;
			}

			span {
				color: #f48120;
			}

		}

	}
}

// PRAGMA Basic Page Layout
.pragma-page-layout {
	height: 100%;
	max-height: 100%;

	md-backdrop {
		z-index: 2;
	}

	>#header-container {
		min-height: $pragma-header-height;
		//height: $pragma-header-height;
		//max-height: $pragma-header-height;

		box-shadow: $whiteframe-shadow-1dp;
		padding: 0 15px;
		z-index: 5;

		>.header {
			min-height: $pragma-header-height;

			&.background-cover {
				background-image: url('../../assets/images/backgrounds/header-bg.png');
				background-size: cover;
			}

			.breadcrumb {
				margin-bottom: 16px;
				font-weight: 500;
				color: rgba(0, 0, 0, 0.54);

				md-icon {
					margin: 0;
				}

				.separator {
					margin: 0 8px;
				}
			}

			.title {
				font-size: 24px;
				font-weight: 300;
			}

			.toolbar {

				.md-button {

					&.md-fab {
						&.md-mini {
							line-height: 35px;
							width: 35px;
							height: 35px;
							margin: 0 5px;

							md-icon {
								color: rgba(255, 255, 255, 0.7);
							}
						}
					}


					&.md-icon-button {
						md-icon {
							//color: #E0E0E0;
							color: #BDBDBD;
						}
					}

				}
			}

		}
	}

	>#content-wrapper {
		position: absolute;
		top: $pragma-header-height;
		right: 0;
		bottom: 0;
		left: 0;

		>.content {
			position: relative;
			overflow: hidden;
			min-height: 100%;

			&:not(.sidenav) {

				.sidenav,
				.toggle-left-sidenav,
				.toggle-right-sidenav {
					display: none;
				}

				.page-content {
					//padding:24px;
				}

			}

			&.sidenav {
				min-height: 100%;
				height: 100%;

				&.single-scroll {
					height: auto;
				}

				.page-content {
					position: relative;
					overflow: auto;
					//padding:30px;
				}

				.toggle-left-sidenav,
				.toggle-right-sidenav {
					position: absolute;
					top: 80px;
					//background: rgba(255,255,255,0.9);
					box-shadow: $whiteframe-shadow-5dp;
					padding: 10px 0;
					cursor: pointer;
					z-index: 2;
				}

				.toggle-left-sidenav {
					border-radius: 0 6px 6px 0;
					left: 0;
				}

				.toggle-right-sidenav {
					border-radius: 6px 0 0 6px;
					right: 0;
				}

				.sidenav {
					z-index: 3;
					box-shadow: $whiteframe-shadow-1dp;

					@include generate-sidenav-widths();

					md-toolbar {
						padding: 8px;

						&.md-toolbar-admin {
							min-height: 56px;
							height: 56px;
						}

						.heading {
							white-space: nowrap;
						}

						md-icon {
							cursor: pointer;
						}

					}

					md-content {
						overflow: hidden;
						padding: 8px;

						.md-title {
							padding: 10px;

							md-icon {
								margin: 0;
							}
						}

					}

					&.md-locked-open {
						z-index: 3;
					}
				}

			}

		}

	}

}

.sidenav-open {
	.pragma-page-layout {
		>#content-wrapper {
			>.content {
				&.sidenav {
					height: 100%;
				}

				.sidenav {
					z-index: 20;
				}

			}
		}
	}
}

#sidebar-resizer {
	width: 8px;
	cursor: e-resize;
	// box-shadow: $whiteframe-shadow-1dp;
	background: transparent;
	z-index: 99;

	&.left {
		margin: 0 0 0 -9px;
	}

	&.right {
		margin: 0 -9px 0 0;
	}

	&::after {
		content: "||";
		position: relative;
		top: 50%;
		text-align: center;
		width: 8px;
	}
}



@media screen and (max-width: $layout-breakpoint-xs) {

	md-sidenav {
		width: $pragma-sidenavMobileWidth  !important;
		min-width: $pragma-sidenavMobileWidth  !important;
		max-width: $pragma-sidenavMobileWidth  !important;
	}

}