/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "toolbar/toolbar.scss";
@import "quick-panel/quick-panel.scss";
@import "directives/data-logger-card/data-logger-card.scss";
@import "directives/consumption-production-card/consumption-production-card.scss";
@import "directives/data-logger-table/table-card.scss";
@import "directives/distribution-card/distribution-card.scss";
@import "directives/fridge-inspector-card/fridge-inspector-card.scss";
@import "directives/numeric-card/numeric-card.scss";
@import "directives/net-metering-card/net-metering-card.scss";
@import "directives/pie-card/pie-card.scss";
@import "directives/fridge-operation-card/fridge-operation-card.scss";
@import "directives/gauge-card/gauge-card.scss";
@import "directives/section-widget/section-widget.scss";
@import "directives/prediction-plot/prediction-plot.scss";
@import "directives/plot-card/plot-card.scss";
@import "directives/spyder-card/spyder-card.scss";
@import "directives/table-card/table-card.scss";
@import "main/breakdown/breakdown.scss";
@import "main/admin/admin.scss";
@import "main/compare/compare.scss";
@import "main/change_pass/change_pass.scss";
@import "main/explore/explore.scss";
@import "main/dashboard/dashboard.scss";
@import "main/preferences/preferences.scss";
@import "main/notification/notification.scss";
@import "main/reporting/reporting.scss";
@import "main/rules/rules.scss";
@import "directives/dialogs/oeeDialog/oeeDialog.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "main/compare/dialogs/compare-filter.scss";
@import "main/auth/login/login.scss";
@import "main/notification/templates/notification-card.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "main/dashboard/dialogs/newWidget/newWidget.scss";
@import "main/notification/dialogs/edit-calendar-notification/edit-calendar-notification.scss";
@import "main/reporting/dialogs/blockProperties/blockProperties.scss";
@import "main/rules/dialogs/newVariable/new-variable.scss";
// endinjector

/* Inject PRAGMA Stylesheets */
@import 'core/scss/partials/pragma-variables';
@import 'core/scss/partials/pragma-mixins';
@import 'core/scss/partials/pragma-layouts-page';
@import 'core/scss/partials/pragma-content-page';
@import 'core/scss/partials/pragma-material';

body {
    #main {
        
        .top-row {
            pointer-events: none;
            
            >div {
                pointer-events: auto;
            }
        }
    }
    
    #footer {
        //border-top: 1px solid #ddd;
        width: 100%;
        //background: #fff url("../assets/images/top-background.jpg") 50% 50% no-repeat;
        padding: 5px 20px;
        position: fixed;
        bottom: 0;
        z-index: 9998;
		min-height:30px;
        
		&.background-image {
			background-image: url("../assets/images/bottom_image_outline_100.png");
			background-size: cover;
		}
		
		
        div {
            margin: 0;
            float: right;
        }
    }
    
    .split {
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;

      overflow-y: auto;
      overflow-x: hidden;
    }

    .gutter {
      background-color: #eee;

      background-repeat: no-repeat;
      background-position: 50%;
    }

    .gutter.gutter-horizontal {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
      cursor: ew-resize;
    }

    .gutter.gutter-vertical {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFCAMAAABl/6zIAAAABlBMVEUAAADMzMzIT8AyAAAAAXRSTlMAQObYZgAAABRJREFUeAFjYGRkwIMJSeMHlBkOABP7AEGzSuPKAAAAAElFTkSuQmCC');
      cursor: ns-resize;
    }

    .split.split-horizontal, .gutter.gutter-horizontal {
      height: 100%;
      float: left;
    }
}

.pragma-orange {
  color: #FB8C00 !important;
}
