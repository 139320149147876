#compare {
	
    .tree {
        color: #999;
        .jstree-checkbox-disabled {
            display: none;
        }
        .jstree-wholerow, .jstree-wholerow-clicked {
            background: none !important;
        }
        .jstree-clicked, .jstree-hovered {
            color: #999;
            background: none !important;
        }
        .jstree-hovered {
            color: #679;
        }        
    }
    
    .search-wrapper {
        margin-bottom: 0;
        .search input {
            padding-left: 6px;
        }
    }
    
    .toggle-left-sidenav, .toggle-right-sidenav {
        top: 75px !important;
        background: #3785d3 !important;
        md-icon {
            font-size: 16px !important;
            color: #cdd3dd !important;
        }
    }
    
    .md-table-pagination {
        border-top: none;
        height: 36px;
        padding: 0;
    }
	
    .chart-container {
        height: 480px;
    }
	
    .chart-title {
        font-size: 18px;
        
        small {
            margin-left: 20px;
        }
    }
    
    .heatmap-color {
        display: inline-block;
        width: 10px; height: 10px;
    }
    
    .vAccordion--default {
        v-pane-header {
            color: #999;
            border: none;
            margin-bottom: 0;
        }
        v-pane-header::after, v-pane-header::before {
            width: 16px;
            height: 2px;
            right: 8px;
            background-color: #999;
        }
        v-pane-content {
            > div {
                padding-bottom: 0;
            }
        }
    }
    
    .schedulePicker {
        color: #999;
        font-size: 10px;
        margin: 0 auto;
        border-collapse: collapse;
        
        th, td { padding: 2px; }
        .th-day { text-align: right; padding: 2px 5px }
        .th-time { text-align: center; }
        th, td { padding: 2px; min-width: 20px; min-height: 20px;}
        td {
            border:1px solid rgba(255,255,255,0.3);
            background-color: rgba(0,0,0,0.1);
        }
        .highlighted {
            background-color: #129c05;
        }
        .semi-highlighted {
            background-color: #7cc67a;
        }
    }
    
    .schedulePicker-overlay {
        display: table-cell;
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.0);
        transition: all 0.5s;
        border-radius: 4px;
    }
    .schedulePicker-overlay span {
        display: none;
        position: relative;
        color: #fff;
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .schedulePicker-overlay:hover {
        background-color: rgba(0,0,0,0.7);
    }
    .schedulePicker-overlay:hover span {
        display: block;
    }
    
	
    // RESPONSIVE
    @media screen and (max-width: $layout-breakpoint-xs) {

        .md-display-1 {
            font-size: 20px;
        }
        
        .schedulePicker {
            .th-day, .th-time {
                display: none;
            }
        }

    }
    @media screen and (max-width: $layout-breakpoint-sm) {

        .schedulePicker {
            .th-day, .th-time {
                display: none;
            }
        }

    }
}

.msg-spinner {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #cdd3dd;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.msg-spinner.static {
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}