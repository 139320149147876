#notifications {
    
	
	/*
	position: relative;
    height: 100%;
    padding-bottom: 64px;

    >.header {
      height: auto;
      min-height: 88px;
      padding: 24px;
    }
    
    >.content {
        min-height: 100%;
    }
    >.page-content {
        min-height: 100%;
    }
    .notificationTable{
        width:96%;
        margin-left:2%;
    }
	*/
	
	.md-table-pagination {
		border-top:none;
	}
	
	table.notificationTable {
		
		padding-bottom:16px;
		margin-bottom:16px;
		
		thead tr.md-row {
			height: 64px;
		}
		
		th {
			font-size: 14px;
		}
		
		th:first-child, td:first-child {
			padding: 0 8px;
			width: 96px;
			min-height: 70px;
		}
		
		th:first-child, td:first-child {
			padding: 0 16px;
		}
		
		th:last-child {
			text-align: center;
			width: 132px;
			white-space: nowrap;
		}
		
		td:last-child {
			width: 132px;
			white-space: nowrap;
		}
		
		.media-wide {
			cursor: pointer;
			margin: 14px !important;
			width: 150px;
			height: 40px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
		
		.media {
			cursor: pointer;
			margin: 6px 14px !important;
			width: 150px;
			height: 56px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
			
	}

		
		
	/*	
    .search-wrapper {
        
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        
        .search {
            height: 36px;
            line-height: 36px;
            padding: 8px 0;
            background: #FFFFFF;
            font-size: 13px;
            
            .icon {
                margin: 0;
                color: rgba(0, 0, 0, 0.54);
            }

            input {
                padding-left: 12px;
                height: 36px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }
	
     .groupX {
        background-color: #f3f3f3;
        background-image: linear-gradient(center top, #fefefe, #f3f3f3);
            background-image: -o-linear-gradient(center top, #fefefe, #f3f3f3);
            background-image: -webkit-linear-gradient(center top, #fefefe, #f3f3f3);
            background-image: -moz-linear-gradient(center top, #fefefe, #f3f3f3);
        border: 1px solid #ccc;
        color: #444;
        cursor: pointer;
        display: block;
        float: left;
        font: bold 11px "Roboto", sans-serif;
        min-width: 18px;
        padding: 8px 7px;
        text-align: center;
        text-transform: none;
    }
    
    .active {
        background-color: #dfdfdf;
        background-image: linear-gradient(center top, #f0f0f0, #dfdfdf);
            background-image: -o-linear-gradient(center top, #f0f0f0, #dfdfdf);
            background-image: -webkit-linear-gradient(center top, #f0f0f0, #dfdfdf);
            background-image: -moz-linear-gradient(center top, #f0f0f0, #dfdfdf);
        border-color: #ccc;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3) inset;
        color: #222;
    }
   
	
	
	
    .md-button.left {
        border-radius: 3px 0 0 3px;
    }

    .md-button.middle {
        border-radius: 0;
        border-left: none;
        border-right: none;
    }

    .md-button.right {
        border-radius: 0 3px 3px 0;
    }
     
    .download-button {
        padding: 0;
        margin-left: 20px;
        width: 32px; height: 32px;
        min-height: 32px;
    }
    
    .toolbar {
        .groupX {
            padding: 5px 6px;
            margin: 0;
        }
    }
	
    
	
	
	
    .charts-top {
        background: #FFFFFF;
        height: 56px;
        padding: 16px 24px;
        
        .title {
            padding-left: 24px;
            font-size: 14px;
            font-weight: 500;
        }
        
        .close-page {
            margin-right: 18px;
        }
        
        .periodSelect {
            width: 100px;
            margin-left: 10px;
            margin-right: 24px;
            
            md-select-value {
                padding-top: 8px;
            }
        }
    }
    */
	
	
	
    .ms-timeline {
        
        &:before {
            display: block;
            position: absolute;
            z-index: 10;
            content: "";
            width: 2px;
            background: #999;
            top: 65px;
            left: 50%;
            bottom: 0;
        }
    
        .ms-timeline-item {
            overflow: hidden;
            position: relative;
            z-index: 20;
            padding: 32px;
            width: 100%;
            margin: 0 auto;
            max-width: 1200px;

            .event-card {
                order: 1;

                ms-card {
                    padding-right: 84px;
                }
            }

            .event-point {
                position: absolute;
                width: 72px;
                height: 72px;
                line-height: 56px;
                text-align: center;
                border-radius: 50%;
                left: 50%;
                margin: 16px 0 0 -36px;
                order: 2;

                md-fab-trigger {
                    md-icon {
                        color: #FFFFFF;
                    }
                }
            }

            .event-detail {
                flex: 50% 0 0;
                padding-top: 28px;
                order: 3;

                .date {
                    font-size: 15px;
                    padding-left: 68px;
                }

                .event {
                    color: rgba(0, 0, 0, 0.54);
                    padding-left: 68px;
                }
            }

            &:nth-child(odd) {

                .event-card {
                    flex-direction: row-reverse;
                }
            }

            &:nth-child(even) {

                .event-card {
                    order: 3;
                    padding-right: 0;

                    ms-card {
                        padding-left: 84px;
                    }
                }

                .event-detail {
                    order: 1;
                    padding-left: 0;
                    text-align: right;

                    .date, .event {
                        padding-right: 68px;
                    }
                }
            }

            &:first-child {
                padding-top: 64px;
            }
        }

        /* Animations */
        .ms-timeline-item {

            &.animate {

                .event-card {
                    animation: slide-in-left 0.4s;
                }

                .event-point {
                    animation: slide-in-bottom 0.4s;
                }

                .event-detail {
                    animation: slide-in-right 0.4s;
                }
            }

            &:nth-child(even) {

                &.animate {

                    .event-card {
                        animation: slide-in-right 0.4s;
                    }

                    .event-point {

                    }

                    .event-detail {
                        animation: slide-in-left 0.4s;
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    md-dialog {
        width: 80%;
        md-dialog-content .form-content .input-block {
            width: 100%;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-xs) {

    md-dialog {
        width: 90%;
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

    #notifications {
        
        .ms-timeline {

            &:before {
                margin-left: -1px;
            }
            
            .ms-timeline-item {
                display: block;

                .event-point {
                    position: relative;
                    left: auto;
                    margin: 0 auto 16px auto;
                    padding: 0;
                }

                .event-detail {
                    padding: 2px;
                    margin-bottom: 16px;
                    //background-color: #f5f5f5;

                    .date,
                    .event {
                        padding-right: 0;
                        padding-left: 0;
                        text-align: center;
                    }
                }

                .event-card {

                    ms-card {
                        margin: 0 auto;
                        padding: 0;
                    }
                }

                &:nth-child(even) {

                    .event-point {
                        position: relative;
                        left: auto;
                        margin: 0 auto 16px auto;
                        padding: 0;
                    }

                    .event-detail {
                        padding: 2px;
                        margin-bottom: 16px;

                        .date,
                        .event {
                            padding-right: 0;
                            padding-left: 0;
                            text-align: center;
                        }
                    }

                    .event-card {

                        ms-card {
                            margin: 0 auto;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}