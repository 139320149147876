$numeric-icon-size: 54px;
$numeric-icon-size-medium: 40px;

net-metering-card {

    .transfer {
        overflow: hidden;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        z-index: 100000;
    
        &.r45 {
            transform: rotate(45deg);
        }
    
        &.r135 {
            transform: rotate(135deg);
        }
    
        .element {
            border-radius: 100%;
            margin: calc(4px*2);
            border: 4px solid silver;
        }
    
        &.active {
            .element {
                border: 4px solid rgb(0,150,136);

                &:nth-child(1) {
                    animation: preloader .6s ease-in-out alternate infinite;
                }
                
                &:nth-child(2) {
                    animation: preloader .6s ease-in-out alternate .2s infinite;
                }
                
                &:nth-child(3) {
                    animation: preloader .6s ease-in-out alternate .4s infinite;
                }

                &:nth-child(4) {
                    animation: preloader .6s ease-in-out alternate .6s infinite;
                }

                &:nth-child(5) {
                    animation: preloader .6s ease-in-out alternate .8s infinite;
                }

            }
        }
    }
    
    @keyframes preloader {
        100% {
            transform: scale(2);
        }
    }
    
    
    
    
    
    
    

    // .md-icon-cnt {
    //     margin-right: 16px;
    // }
    // .desc-cnt {
    //     min-width: 140px;
    // }
    // md-icon {
    //     font-size: $numeric-icon-size!important;
    //     width: $numeric-icon-size!important;
    //     height: $numeric-icon-size!important;
    //     min-width: $numeric-icon-size!important;
    //     min-height: $numeric-icon-size!important;
    //     line-height: $numeric-icon-size!important;
    // }
    // h2 {
    //     margin: 0;
    //     font-size: 3rem;
    //     span {
    //         font-size: 2rem;
    //     }
    // }
    // p {
    //     margin: 0;
    //     font-size: 1.5rem;
    // }
    // .layout-row+.layout-row {
    //     margin-top: 5px;
    // }
    // .timestamp{
    //     font-size: 12px;
    // }
}

/* Large Devices, Wide Screens */
// @media only screen and (max-width : 1200px) {
//     numeric-card {
//         md-icon {
//             font-size: $numeric-icon-size-medium!important;
//             width: $numeric-icon-size-medium!important;
//             height: $numeric-icon-size-medium!important;
//             min-width: $numeric-icon-size-medium!important;
//             min-height: $numeric-icon-size-medium!important;
//             line-height: $numeric-icon-size-medium!important;
//         }
//         h2 {
//             font-size: 2.2rem;
//             span {
//                 font-size: 1.7rem;
//             }
//         }
//         p {
//             font-size: 1.4rem;
//         }
//         .timestamp{
//             font-size: 11px;
//         }
//     }
// }

/* Small Devices, Tablets */
// @media only screen and (max-width : 992px) {
//     numeric-card {
//         h2 {
//             font-size: 2rem;
//             span {
//                 font-size: 1.5rem;
//             }
//         }
//         p {
//             font-size: 1.3rem;
//         }
//     }
// }