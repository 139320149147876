/*----------------------------------------------------------------*/
/*  ms-card
/*----------------------------------------------------------------*/

.ms-card {
    display: flex;
    flex-direction: row;
    padding: 8px;

    .ms-card-content-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 400px;
        border-radius: 2px;
        //background: rgba(255, 255, 255, 1);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.098), 0px 1px 5px 0px rgba(0, 0, 0, 0.084);
    }
}

