#explore {

    .tree {
        color: #999;

        .jstree-checkbox-disabled {
            display: none;
        }

        .jstree-wholerow,
        .jstree-wholerow-clicked {
            background: none !important;
        }

        .jstree-clicked,
        .jstree-hovered {
            color: #999;
            background: none !important;
        }

        .jstree-hovered {
            color: #679;
        }
    }

    .graph-message {
        margin-top: -40px;
        margin-left: 0;
    }

    .ng-dygraphs {

        .dygraph-legend {
            top: -24px !important;
            left: 0 !important;
            position: absolute;
        }

        .dygraph-axis-label {
            font-size: 12px;
        }
    }

    .search-wrapper {
        margin-bottom: 0;

        .search input {
            padding-left: 6px;
        }
    }

    .toggle-left-sidenav {
        top: 75px !important;
        background: #3785d3 !important;

        md-icon {
            font-size: 16px !important;
            color: #cdd3dd !important;
        }
    }

    table.md-table {
        th {
            background: #303e58;
        }

        th,
        td {
            color: #cdd3dd;
        }
    }

}

/* For y-axis label */
.dygraph-label {
    font-size: 12px;
}

.dygraph-label-rotate-left {
    margin: 0 20px;
    text-align: center;
    /* See http://caniuse.com/#feat=transforms2d */
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}

/* For y2-axis label */
.dygraph-label-rotate-right {
    margin: 0 -20px;
    text-align: center;
    /* See http://caniuse.com/#feat=transforms2d */
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
}

.hidey2 .dygraph-axis-label-y2,
.hidey2 .dygraph-y2label {
    color: transparent;
}

.msg-spinner {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #cdd3dd;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.msg-spinner.static {
    -webkit-animation: none;
    animation: none;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px)
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/*

#explore {
    position: relative;
    height: 100%;
    padding-bottom: 64px;

    >.header {
      height: auto;
      min-height: 88px;
      padding: 24px;
    }
    
    >.content {
        min-height: 100%;
    }

    .tree {
        width: 100%;
        overflow-x: hidden;
    }
    
    .search-wrapper {
        
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        
        .search {
            height: 36px;
            line-height: 36px;
            padding: 8px;
            background: #FFFFFF;
            font-size: 13px;

            .icon {
                margin: 0;
                color: rgba(0, 0, 0, 0.54);
            }

            input {
                padding-left: 12px;
                height: 36px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    md-sidenav {
        width: $quickPanelMobileWidth;
        min-width: $quickPanelMobileWidth;
        max-width: $quickPanelMobileWidth;
    }
}

*/