$numeric-icon-size: 80px;
$numeric-icon-size-medium: 40px;

$alarms-icon-size: 28px;

fridge-operation-card {
    .md-icon-cnt {
        margin-right: 16px;
    }
    .desc-cnt {
        min-width: 140px;
    }

    .fridge-icon {
        font-size: $numeric-icon-size !important;
        width: $numeric-icon-size !important;
        height: $numeric-icon-size !important;
        min-width: $numeric-icon-size !important;
        min-height: $numeric-icon-size !important;
        line-height: $numeric-icon-size !important;
    }

    .alarms-icon {
        font-size: $alarms-icon-size !important;
        width: $alarms-icon-size !important;
        height: $alarms-icon-size !important;
        min-width: $alarms-icon-size !important;
        min-height: $alarms-icon-size !important;
        line-height: $alarms-icon-size !important;
    }
    h2 {
        margin: 0;
        font-size: 3rem;
        span {
            font-size: 2rem;
        }
    }
    p {
        margin: 0;
        font-size: 1.5rem;
    }
    .layout-row + .layout-row {
        margin-top: 5px;
    }
    .timestamp {
        font-size: 12px;
    }
}

/* Large Devices, Wide Screens */
// @media only screen and (max-width: 1200px) {
//     fridge-operation-card {
//         md-icon {
//             font-size: $numeric-icon-size-medium !important;
//             width: $numeric-icon-size-medium !important;
//             height: $numeric-icon-size-medium !important;
//             min-width: $numeric-icon-size-medium !important;
//             min-height: $numeric-icon-size-medium !important;
//             line-height: $numeric-icon-size-medium !important;
//         }
//         h2 {
//             font-size: 2.2rem;
//             span {
//                 font-size: 1.7rem;
//             }
//         }
//         p {
//             font-size: 1.4rem;
//         }
//         .timestamp {
//             font-size: 11px;
//         }
//     }
// }

// /* Small Devices, Tablets */
// @media only screen and (max-width: 992px) {
//     fridge-operation-card {
//         h2 {
//             font-size: 2rem;
//             span {
//                 font-size: 1.5rem;
//             }
//         }
//         p {
//             font-size: 1.3rem;
//         }
//     }
// }
