.dashboard {

    .emptyDashboard {
        height: 100%;
        padding: 0 50px;
        text-align: center;
    }

    .hint {
        padding: 2px;
        line-height: 26px;
        margin-right: 10px;
    }

}

.grid-stack>.grid-stack-item>.ui-resizable-se {
    transform: rotate(0deg) !important;
    opacity: 0.7;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+nhxg7wAAAElJREFUKJG1kMEKACAIQ7fo/395nSILKQ3cSeTJ5igJQQkASKJFL6x6FCS55kS8GicZcDIFRdgomXgbfDLfTtenvd1fEa8o3m4AucMhMliAl6IAAAAASUVORK5CYII=)
}

.dashboardItem {
    cursor: pointer;
    align-items: center;
    min-height: 48px;
    height: auto;
    display: flex;
    font-size: inherit;
    text-align: left;
    text-transform: none;

}

.dashboardItem:hover {
    background-color: rgba(78, 77, 91, 0.1);

}

#dashboard {
    .ms-widget-front {
        border-radius: 4px;

        >div {
            border-bottom: 1px ridge #ccc !important;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            height: 50px !important;
            min-height: 50px !important;
            max-height: 50px !important;

            >.h3 {
                font-size: 12px;
            }
        }
    }

    .ms-widget-front::before {
        pointer-events: none;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        /* background: -moz-linear-gradient(top, rgba(224,229,234,0.4) 0%, rgba(224,229,234,0) 31%, rgba(224,229,234,0) 37%, rgba(224,229,234,0) 100%);
        background: -webkit-linear-gradient(top, rgba(224,229,234,0.4) 0%,rgba(224,229,234,0) 31%,rgba(224,229,234,0) 37%,rgba(224,229,234,0) 100%);
        background: linear-gradient(to bottom, rgba(224,229,234,0.4) 0%,rgba(224,229,234,0) 31%,rgba(224,229,234,0) 37%,rgba(224,229,234,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66e0e5ea', endColorstr='#00e0e5ea',GradientType=0 );*/
    }

    [widget="widget"] {
        text-align: center;

        >div {
            padding: 10px;
            height: calc(100% - 50px);
            overflow: hidden;
        }
    }

}

#dashboard1 {
    position: relative;
    height: 100%;
    padding-bottom: 64px;

    .grid-stack-item-content {
        box-shadow: $whiteframe-shadow-1dp;
    }

    .addWidgetButton {
        float: right;
        color: blue;
        margin-right: 20px;
        position: relative;
        font-weight: bold;
        font-size: 14px;
    }

    >.header {
        height: auto;
        min-height: 88px;
        padding: 24px;
    }

    >.content {
        min-height: 100%;
    }

    .tree {
        width: 100%;
        overflow-x: hidden;
    }

    .top-toolbar {
        padding: 12px 16px 15px 16px;
        background: #fff;
    }

    .groupX {
        background-color: #f3f3f3;
        background-image: linear-gradient(center top, #fefefe, #f3f3f3);
        background-image: -o-linear-gradient(center top, #fefefe, #f3f3f3);
        background-image: -webkit-linear-gradient(center top, #fefefe, #f3f3f3);
        background-image: -moz-linear-gradient(center top, #fefefe, #f3f3f3);
        border: 1px solid #ccc;
        color: #444;
        cursor: pointer;
        display: block;
        float: left;
        font: bold 11px "Roboto", sans-serif;
        min-width: 18px;
        padding: 8px 7px;
        text-align: center;
        text-transform: none;
    }

    .toolbar {
        .groupX {
            padding: 5px 6px;
            margin: 0;
        }
    }

    .md-button.middle {
        border-radius: 0;
        border-left: none;
        border-right: none;
    }

    .sidhov {
        cursor: pointer;
    }

    .search-wrapper {

        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;

        .search {
            height: 36px;
            line-height: 36px;
            padding: 8px;
            background: #FFFFFF;
            font-size: 13px;

            .icon {
                margin: 0;
                color: rgba(0, 0, 0, 0.54);
            }

            input {
                padding-left: 12px;
                height: 36px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }

    .daterange-wrapper {

        position: relative;
        min-width: 256px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        background: #f3f3f3;

        .daterange {
            height: 34px;
            line-height: 34px;
            padding: 5px;
            background: #f3f3f3;
            font-size: 16px;
            font-weight: 500;

            .icon {
                color: rgb(33, 33, 33);
                margin: 0;
            }

            input {
                background: #f3f3f3;
                padding-left: 12px;
                height: 34px;
                color: rgb(33, 33, 33);
            }

        }

    }

    .add-button {
        position: absolute;
        top: 24px;
        right: 24px;

    }

    .dashboardButton {
        md-icon {
            color: #ccc;
        }
    }

    .charts-top {
        background: #FFFFFF;
        height: 56px;
        padding: 16px 24px;

        .title {
            padding-left: 24px;
            font-size: 14px;
            font-weight: 500;
        }

        .close-page {
            margin-right: 18px;
        }

        .periodSelect {
            width: 100px;
            margin-left: 10px;
            margin-right: 24px;

            md-select-value {
                padding-top: 8px;
            }
        }
    }

    .sidenav {
        md-list {
            md-list-item {
                &.selected {
                    .md-button {
                        font-weight: 500;
                        color: #000000;
                        background-color: rgba(158, 158, 158, 0.2);
                    }

                }
            }
        }
    }


    .ms-timeline {

        &:before {
            display: block;
            position: absolute;
            z-index: 10;
            content: "";
            width: 2px;
            background: rgba(0, 0, 0, 0.08);
            top: 144px;
            left: 50%;
            bottom: 0;
            margin-left: 159px;
        }

        .ms-timeline-item {
            overflow: hidden;
            position: relative;
            z-index: 20;
            padding: 32px;
            width: 100%;
            margin: 0 auto;
            max-width: 1200px;

            .event-card {
                order: 1;

                ms-card {
                    padding-right: 84px;
                }
            }

            .event-point {
                position: absolute;
                width: 72px;
                height: 72px;
                line-height: 56px;
                text-align: center;
                border-radius: 50%;
                left: 50%;
                margin: 16px 0 0 -36px;
                order: 2;

                md-fab-trigger {
                    md-icon {
                        color: #FFFFFF;
                    }
                }
            }

            .event-detail {
                flex: 50% 0 0;
                padding-top: 28px;
                order: 3;

                .date {
                    font-size: 15px;
                    padding-left: 68px;
                }

                .event {
                    color: rgba(0, 0, 0, 0.54);
                    padding-left: 68px;
                }
            }

            &:nth-child(odd) {

                .event-card {
                    flex-direction: row-reverse;
                }
            }

            &:nth-child(even) {

                .event-card {
                    order: 3;
                    padding-right: 0;

                    ms-card {
                        padding-left: 84px;
                    }
                }

                .event-detail {
                    order: 1;
                    padding-left: 0;
                    text-align: right;

                    .date,
                    .event {
                        padding-right: 68px;
                    }
                }
            }

            &:first-child {
                padding-top: 64px;
            }
        }

        /* Animations */
        .ms-timeline-item {

            &.animate {

                .event-card {
                    animation: slide-in-left 0.4s;
                }

                .event-point {
                    animation: slide-in-bottom 0.4s;
                }

                .event-detail {
                    animation: slide-in-right 0.4s;
                }
            }

            &:nth-child(even) {

                &.animate {

                    .event-card {
                        animation: slide-in-right 0.4s;
                    }

                    .event-point {}

                    .event-detail {
                        animation: slide-in-left 0.4s;
                    }
                }
            }
        }
    }
}

md-dialog {

    min-width: 400px;

    &.new-widget-dialog {
        width: 80% !important;
        max-width: 80% !important;
        overflow: hidden;
    }

    md-dialog-content {
        position: relative;
        padding: 0;

        input,
        label {
            font-size: 16px;
        }

        .form-content {
            padding: 16px;
            font-size: 16px;

            .hasError {
                color: red;
            }

            p.message {
                margin: 20px;
            }

            .form-title {
                font-weight: bold;
                margin: 16px 0;
                padding: 0 8px;
                border-bottom: 1px solid #ccc;

                span {
                    font-weight: bold;
                }
            }

            .input-block {
                padding: 12px;

                md-input-container,
                .md-datepicker-input-container {
                    margin: 0;
                    width: 100%;
                }

                md-switch {
                    margin: 0;
                }

                .md-errors-spacer {
                    height: 0;
                    min-height: 0;
                }

                .ta-editor.ta-html,
                .ta-scroll-window.form-control,
                .ta-scroll-window>.ta-bind {
                    min-height: 150px;
                }
            }

        }

        md-dialog-actions {
            border-top: 1px solid rgba(0, 0, 0, .12);
            background-color: rgba(0, 0, 0, .03);
        }

    }
}



// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    md-dialog {
        width: 80%;

        md-dialog-content .form-content .input-block {
            width: 100%;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-xs) {

    md-dialog {
        width: 90%;
    }


}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

    /*
    #events {
        
        .ms-timeline {

            &:before {
                margin-left: -1px;
            }
            
            .ms-timeline-item {
                display: block;

                .event-point {
                    position: relative;
                    left: auto;
                    margin: 0 auto 16px auto;
                    padding: 0;
                }

                .event-detail {
                    padding: 2px;
                    margin-bottom: 16px;
                    background-color: #f5f5f5;

                    .date,
                    .event {
                        padding-right: 0;
                        padding-left: 0;
                        text-align: center;
                    }
                }

                .event-card {

                    ms-card {
                        margin: 0 auto;
                        padding: 0;
                    }
                }

                &:nth-child(even) {

                    .event-point {
                        position: relative;
                        left: auto;
                        margin: 0 auto 16px auto;
                        padding: 0;
                    }

                    .event-detail {
                        padding: 2px;
                        margin-bottom: 16px;

                        .date,
                        .event {
                            padding-right: 0;
                            padding-left: 0;
                            text-align: center;
                        }
                    }

                    .event-card {

                        ms-card {
                            margin: 0 auto;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
*/

}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
    .dashboard {

        .grid-stack > .grid-stack-item {
            height: unset !important;
            
            .grid-stack-item-content {
                position: unset;
            }
        }
    }
}

.daterangepicker {
    display: none;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    .applyBtn {
        font-size: 11px;
        min-width: 70px;
        min-height: 30px;
        line-height: 30px;
    }

    .fa {
        font-style: normal;
        font-family: icomoon;
    }

    .fa-chevron-left:before {
        content: "\e712";
    }

    .fa-chevron-right:before {
        content: "\e713";
    }
}

.dashboard {

    .jstree-proton {
        .jstree-node {
            position: relative !important;
            min-height: 48px;

            .jstree-ocl {
                margin-top: 0.5em;
            }

            .jstree-anchor {
                vertical-align: bottom;
                -webkit-margin-before: 1em;
                -webkit-margin-after: 1em;
                -webkit-margin-start: 0px;
                -webkit-margin-end: 0px;
            }

            .jstree-wholerow {
                height: 48px !important;
            }
        }
    }

    #jstree-marker {
        z-index: 3;
    }
}