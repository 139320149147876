/*----------------------------------------------------------------*/
/*  PRAGMA Mixins
/*----------------------------------------------------------------*/

// Sidenav widths classes [sidenav-width-xxx]
@mixin generate-sidenav-widths() {
	@each $width in $sidenavWidths {
		&.sidenav-width-#{$width} {
			width: #{$width}px;
			min-width: #{$width}px;
			max-width: #{$width + 200}px;
		}
	}
}


// Icon Sizes classes [icon-size-xxx]
@mixin generate-icon-sizes() {
	@each $size in $iconSizes {
		&.icon-size-#{$size} {
			font-size: #{$size}px;
			width: #{$size}px;
			height: #{$size}px;
			min-width: #{$size}px;
			min-height: #{$size}px;
			line-height: #{$size}px;
		}
	}
}