$pragma-toolbarHeight: 32px;

#language-menu-content::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#language-menu-content::-webkit-scrollbar {
	width: 6px;
	background-color: grey;
}

#language-menu-content::-webkit-scrollbar-thumb {
	background-color: grey;
}

#user-menu-content {
	.md-in-menu-bar {
		.md-button {
			span {
				color:rgba(0,0,0,0.8);
			}
		}
	}
}


#layout-horizontal-navigation {

    #toolbar-navigation-container {
        z-index: 55;
		
		&.background-image {
			background-image: url("../assets/images/top_image_outline_100.png");
			background-size: cover;
		}

        #toolbar {
            height: $pragma-toolbarHeight;
            min-height: $pragma-toolbarHeight;
            max-height: $pragma-toolbarHeight;
            box-shadow: none;
            background: none;
			z-index: 55 !important;
			
            .top-row {
				
                .logo {
					max-width: 300px;
					width: 80px;
					max-height: 75px;
					margin: 0 2px 0 8px;
					padding: 5px 0;
					
					/*
                    .logo-image {
                        display: block;
                        background: material-color('light-blue', '600');
                        width: 32px;
                        min-width: 32px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        color: #FFFFFF;
                        font-size: 16px;
                        font-weight: 500;
                        border-radius: 2px;
                    }

                    .logo-text {
                        color: rgba(0, 0, 0, 0.87);
                        margin-left: 16px;
                        font-size: 16px;
                    }
					*/
					
                }
				
				#user-menu {
					height: $pragma-toolbarHeight;
					
					.user-button {
						padding: 0 8px;
						height: $pragma-toolbarHeight;
						min-height: $pragma-toolbarHeight;
						line-height: $pragma-toolbarHeight;
						vertical-align: top;
						
						
						.avatar-wrapper {
							
							.avatar {
								width: $pragma-toolbarHeight - 6px;
								min-width: $pragma-toolbarHeight - 6px;
								height: $pragma-toolbarHeight - 6px;
								line-height: $pragma-toolbarHeight - 6px;
								margin: 0 4px 0 0;
							}
							
							.notificationCircle{
								position: absolute;
								bottom: -3px;
								right: 5px;
								border-radius: 100%;
								width: 20px;
								height: 20px;
								background: #F44336;
							}
							
							.notificationNumber{
								position: absolute;
								bottom: -8.5px;
								right:5px;
								color: #fff;
								text-align: center;
								font-size: 14px;
							}
							
						}
					
						.username {
							margin: 0 8px 0 6px;
							//color: rgba(0, 0, 0, 0.7);
							font-size:1.2rem;
						}
						
					}
				
				}
				
				#notifications-menu {
					padding: 0;
					height: $pragma-toolbarHeight;
					
					.notifications-button {
						margin: 0;
						padding: 0 12px;
						border-radius:0;
						vertical-align: top;
						min-height: $pragma-toolbarHeight;
						height: $pragma-toolbarHeight;
						line-height: $pragma-toolbarHeight;
						min-width: $pragma-toolbarHeight;
						
						.iso {
							text-transform: uppercase;
							font-size:12px;
							font-weight:500;
							color: rgba(0, 0, 0, 0.7);
						}
						
						.flag {
							margin-right: 4px;
						}
						
					}
					
				}
				
				.toolbar-separator {
					height: $pragma-toolbarHeight;
					width: 1px;
					background: rgba(0, 0, 0, 0.12);
				}
			
            }
        }
    }
}

// RESPONSIVE
@media only screen and (max-width: $layout-breakpoint-sm) {
	
    #layout-horizontal-navigation {

        #toolbar-navigation-container {
			
			#toolbar {
				height: $toolbarHeight;
				min-height: $toolbarHeight;
				max-height: $toolbarHeight;
				
				.top-row {
					
					.logo {
						padding: 2.5px 0;
						max-width: 200px;
						width: 60px;
						max-height: 50px;
					}
					
					#user-menu {
						height: $toolbarHeight;
						
						.user-button {
							height: $toolbarHeight;
							min-height: $toolbarHeight;
							line-height: $toolbarHeight;
							
							.avatar-wrapper {
								
								// .avatar {
								// 	width: $toolbarHeight - 20px;
								// 	min-width: $toolbarHeight - 20px;
								// 	height: $toolbarHeight - 20px;
								// 	line-height: $toolbarHeight - 20px;
								// }
								
							}
							
						}
					
					}
					
					#notifications-menu {
						height: $toolbarHeight;
						
						.notifications-button {
							min-height: $toolbarHeight;
							height: $toolbarHeight;
							line-height: $toolbarHeight;
							min-width: $toolbarHeight;
							
						}
						
					}
					
					.toolbar-separator {
						height: $toolbarHeight;
					}
				
				}
			}
        }
    }
}