#pieCard {
    .measureTitle{
        font-style: bold;
        font-size: 20px;
    }
     .measureText{
        font-size: 16px;
    }
    .measureUnit{
         font-style: italic;
    }
}
#txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    margin-top: 5%; 
}

#pieCardReport {
	md-progress-circular {
		top: 50%;
		left: 50%;
		z-index: 10;
		position: absolute;
		
		path {
			stroke: rgb(244,67,55) !important;
		}
		
	}
}