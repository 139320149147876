#changePass_auth,
#changePass_landing {
    height: 100%;
    overflow: hidden;
    background-size: cover;
	
    #login-intro {
        padding: 128px;
		
        .title {
            font-size: 42px;
        }

        .description {
            padding-top: 8px;
            max-width: 800px;
        }
    }
	
    .md-button {
        border-radius: 4px;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ecf0f2 inset;
    }
	
    #login-form-wrapper {
        width: 416px;
        min-width: 416px;
        max-width: 416px;
        height: 100%;

        #login-form {
            padding: 100px 32px 32px 32px;

            .title {
                font-size: 21px;
            }

            .description {
                padding-top: 8px;
            }

            form {
                width: 100%;
                text-align: left;
                padding-top: 32px;

                md-checkbox {
                    margin: 0;
                }

                .remember-forgot-password {
                    font-size: 13px;
                    margin-top: 8px;

                    .remember-me {
                        margin-bottom: 16px
                    }

                    .forgot-password {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 16px
                    }
                }

                .submit-button {
                    width: 100%;
                    margin: 16px auto;
                    display: block;
                }
            }
        }
    }
}


#changePass_auth {
    #login-intro {
		background: #628b45 url("/assets/images/backgrounds/login-bg.png") bottom left no-repeat;
		background-size: 100%;
		
        .title {
            color: #cdd3dd;
			font-weight: 300;
        }

        .description {
            color: #cfddcd;
			font-size: 14px;
        }
    }
	
    #login-form-wrapper {
		background: #cfddcd;
		
        #login-form {
            .title {
                color: #242e42;
            }
		}
    }
}

#changePass_landing {
    #login-intro {
		background: #fff url("/assets/images/logos/smarteverything.png") bottom left no-repeat;
		background-size: 100%;
		
        .title {
            color: #971881;
			font-weight: 600;
        }

        .description {
            color: #f3802d;
			font-size: 18px;
        }
    }
	
    #login-form-wrapper {
		//background: #fff0d0;
		background: #971881;
		
        #login-form {
            .title {
                //color: #971881;
                color: #f2f2f2;
				font-weight: 500;
            }
		}
		
		form {
			.md-input {
				border-bottom-color: rgba(255, 255, 255, 0.32);
			}
			::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				color: rgba(255, 255, 255, 0.40);
				opacity: 1; /* Firefox */
			}

			:-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: rgba(255, 255, 255, 0.40);
			}

			::-ms-input-placeholder { /* Microsoft Edge */
				color: rgba(255, 255, 255, 0.40);
			}
			.md-button {
				background-color: #f3802d;
			}
			.md-button[disabled] {
				background-color: rgba(0,0,0,0.22);
			}
		}
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #login {

        #login-form-wrapper {
            width: 100%;
            min-width: 100%;
            max-width: 100%;

            #login-form {
                text-align: center;
                padding: 24px;

                .md-button {

                    &.google,
                    &.facebook {
                        width: 80%;
                    }
                }
            }
        }
    }

}