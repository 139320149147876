.ms-card {

    .notification-card {

        .caption {
            text-align: center;
        }
        
        .chart {
            min-width: 360px;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

    .ms-card {
        
        .notification-card {
        
            .chart {
                min-width: initial;
            }
            
        }
    }
}
